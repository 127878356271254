import { h } from 'preact'
import { formatNumber } from 'utils/format-number'
import useCalculatorRateView, { CalculatorRateViewProps } from 'common-modules/calculator/calculator-rate'
import { useEffect } from 'preact/hooks'
import marketService from 'services/market'
import { MarketHistory } from 'models/market'
import { ChartistPayload } from 'models/chartist'
import * as Chartist from 'chartist'
import { i18n } from 'i18n/i18n'

const MainCalculatorRateView = (props: CalculatorRateViewProps) => {
  const { rate, pair } = useCalculatorRateView(props)

  useEffect(() => {
    drawChart()
  }, [pair])

  const drawChart = async (): Promise<void> => {
    const data = await marketService.fetchMatketHistory(pair, '7-days', '1d')
    createChart(data)
  }

  const createChart = (data: MarketHistory): void => {
    const chartData: ChartistPayload = {
      series: [[]],
      labels: [],
    }

    const options: Chartist.ILineChartOptions = {
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      fullWidth: true,
      showArea: true,
    }

    chartData.labels = data.fx_series.map((record) => record[0])
    chartData.series = [data.fx_series.map((record) => record[1])]

    new Chartist.Line('#mini-chart-rate', chartData, options)
  }

  const getTooltipText = (): string => {
    const currency = pair.split('_')[0]

    // prettier-ignore
    return props.direction === 'SELL'
      ? i18n('calculator.buyTooltip', {currency, rate: formatNumber(rate, 4,)})
      : i18n('calculator.sellTooltip', {currency, rate: formatNumber(rate, 4,)})
  }

  return (
    <div className="hero-form__rate-texts bem-icon is-connector">
      <div className="hero-form__rate-headers">
        <span className="hero-form__rate-heading">{i18n('calculator.currentRate')}</span>
        <div className="hero-form__rate-number-and-chart">
          <div
            className="hero-form__rate-tooltip hint--top hint--large hint--rounded"
            role="tooltip"
            aria-label={getTooltipText()}
          >
            <div className="hero-form__big-rate">{formatNumber(rate, 4)}</div>
            <div className="hero-form__exchange-info-icon bem-icon exchange-info-icon" />
          </div>
          <div
            className="hero-form__mini-chart hint--top hint--large hint--rounded"
            aria-label={i18n('calculator.miniChart')}
            role="img"
          >
            <div className="mini-chart__tooltips-box" id="mini-chart-rate" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainCalculatorRateView
