import { SettingsResponse } from 'models/settings'
import { promiseWrapper } from 'utils/promise'

declare let USER_BASE_URL_IK: string

const settingsService = {
  fetchCalculatorSettings: async (): Promise<SettingsResponse> => {
    return promiseWrapper<SettingsResponse, never>(
      `${USER_BASE_URL_IK}/api/public/settings?keys[]=currency&keys[]=direct-exchange-minimal-amounts&keys[]=minimal-payout-amounts&keys[]=currencies-not-supported&keys[]=route-fusion&keys[]=crown-agents`,
      'GET',
    )
  },
  fetchWbrSettings: async (): Promise<SettingsResponse> => {
    return promiseWrapper<SettingsResponse, never>(
      `${USER_BASE_URL_IK}/api/public/settings?keys[]=direct-exchange-guest-order`,
      'GET',
    )
  },
  fetchForeignSettings: async (): Promise<SettingsResponse> => {
    return promiseWrapper<SettingsResponse, never>(
      `${USER_BASE_URL_IK}/api/public/settings?keys[]=currency&keys[]=direct-exchange-minimal-amounts&keys[]=minimal-payout-amounts&keys[]=currencies-not-supported&keys[]=bank-transfer-countries&keys[]=route-fusion&keys[]=crown-agents`,
      'GET',
    )
  },
}

export default settingsService
