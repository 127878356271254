import cookieService from 'services/cookies'

export enum SupportedLanguages {
  pl = 'pl',
  en = 'en',
}

export const getLangFromCookie = (): string | null => {
  const cookieLang = cookieService.getItem('language')
  const indexInArray = SupportedLanguages[cookieLang]
  if (SupportedLanguages[cookieLang] >= 0) {
    return SupportedLanguages[indexInArray]
  }
  return null
}

export const getLangFromUrl = (): string => {
  const url = location.hostname
  return url.includes('en.') ? 'en' : 'pl'
}

export const getCurrentLanguage = (): string =>
  typeof window !== 'undefined' ? getLangFromCookie() || getLangFromUrl() || SupportedLanguages.pl : 'pl'
