import { h } from 'preact'
import { i18n } from 'i18n/i18n'

interface MainCalculatorRouteFusionViewProps {
  baseCurrency: string
}

const MainCalculatorRouteFusionView = ({ baseCurrency }: MainCalculatorRouteFusionViewProps) => {
  const currencyName = i18n(`currenciesDopelniacz.${baseCurrency}`)

  return (
    <p className="hero-form__route-fusion-info">{`${i18n('calculator.routeFusionInfo', {
      currencyName,
      baseCurrency,
    })}`}</p>
  )
}

export default MainCalculatorRouteFusionView
