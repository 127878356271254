import { Component } from 'preact'
import settingsService from 'services/settings'

export interface CalculatorAppState {
  settings: {
    symbolsSorted: string[]
    currenciesNotSupported: string[]
    routeFusionCurrencies: string[]
    crownAgentsCurrencies: string[]
  }
}

export default class CalculatorApp extends Component<{}, CalculatorAppState> {
  constructor(props) {
    super(props)

    this.state = {
      settings: {
        symbolsSorted: [],
        currenciesNotSupported: [],
        routeFusionCurrencies: [],
        crownAgentsCurrencies: [],
      },
    }
  }

  public render() {
    return null
  }

  public async componentDidMount() {
    try {
      const settings = await settingsService.fetchCalculatorSettings()

      this.setState({
        settings: {
          symbolsSorted: settings.currency.symbols_sorted,
          currenciesNotSupported: settings['currencies-not-supported'],
          routeFusionCurrencies: settings['route-fusion'].currencies,
          crownAgentsCurrencies: settings['crown-agents'].currencies,
        },
      })
    } catch (e) {
      //
    }
  }

  public removeNotSupportedCurrencies = (currenciesList: string[]): string[] => {
    const { currenciesNotSupported } = this.state.settings
    return currenciesList.filter((currency) => !currenciesNotSupported.includes(currency)) //IKMKTG-178 //IKMKTG-275
  }

  get currencies(): string[] {
    const { symbolsSorted } = this.state.settings
    return this.removeNotSupportedCurrencies(symbolsSorted)
  }
}
