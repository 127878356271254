export default {
  currency: {
    zl: 'PLN',
  },
  calculator: {
    buyLabelName: 'i have',
    sellLabelName: 'i will receive',
    currentRate: 'Current rate:',
    continueButton: 'Proceed to exchange',
    miniChart: 'The mini-chart shows the fluctuations of the average rate over the last 7 days.',
    sellTooltip:
      'This is a sales rate - we sell you {{currency}} for PLN {{rate}}. The lower the rate, the more beneficial it is for you. Automatic update every 10 seconds.',
    buyTooltip:
      'This is a buying rate - we buy {{currency}} from you for PLN {{rate}}. The higher the rate, the more beneficial it is for you. Automatic update every 10 seconds.',
    connectionError: 'Problem with downloading exchange rates. Check the internet connection.',
    routeFusionInfo: '{{currencyName}} {{baseCurrency}} are not avaible for sale',
  },
  creditCalculator: {
    monthlyLoan: 'Monthly loan instalment',
    loanTerm: 'Loan term remaining',
    whichBank: 'In which bank are you repaying your loan?',
    save: 'Save!',
    years: 'years',
    inYear: 'in a year',
    toEnd: 'by the end of the loan',
    installmentAmount: 'The installment amount is too small',
  },
  profitCalculator: {
    transferNotSupported: 'Wybrany bank nie wspiera przelewów tej waluty.',
    transferFree: 'Nie pobieramy żadnych opłat za wysłanie wymienionych środków!',
    transferFee:
      'Za przelew do wybranego przez Ciebie banku jest pobierana opłata bankowa – {{transferFee}} PLN. Opłata została uwzględniona w wyliczeniu.',
  },
  preactSelect: {
    search: 'Search...',
  },
  frontpageExitPopup: {
    accountExists: 'It looks like you already have an account. We send a discount to new customers.',
    error: 'An error has occurred',
  },
  emailValidator: {
    requiredField: 'This field must be checked',
    notEmptyField: 'This field cannot be empty',
    minValue: 'The manimum value is',
    maxValue: 'The maximum value is',
    incorrectEmail: 'Please provide valid email address',
    toShortPassowrd: 'Password is too short',
  },
  currencyAlert: {
    isLower: 'is lower',
    isHigher: 'is higher',
    mustBeLessThan: 'The rate must be a number less than 99.9999',
    alertAlreadyExists: 'A rate alert for the specified rate already exists',
    maxAlerts: 'You cannot subscribe to more alerts. (max. 10)',
    error: 'Something went wrong, please try again in a moment.',
    alertHash: 'currency-alert',
  },
  ratesUpdate: {
    error: 'Failed to download data. Retry in',
    timeUnit: 'sec',
  },
  currencies: {
    AED: 'UAE dirham',
    ARS: 'Argentine peso',
    AUD: 'Australian dollar',
    BDT: 'Bangladeshi taka',
    BGN: 'Bulgarian lev',
    BRL: 'Brazilian real',
    CAD: 'Canadian dollar',
    CHF: 'Swiss franc',
    CLP: 'Chilean peso',
    CNY: 'Chinese yuan',
    COP: 'Colombian peso',
    CZK: 'Czech koruna',
    DKK: 'Danish crown',
    EGP: 'Egyptian Pound',
    EUR: 'Euro',
    FJD: 'Fijian Dollar',
    GBP: 'British pound',
    GHS: 'Ghanaian Cedi',
    HKD: 'Hong Kong dollar',
    HRK: 'Croatian kuna',
    HUF: 'Hungarian forint',
    ILS: 'Israeli new shekel',
    INR: 'Indian Rupee',
    JOD: 'Jordanian dinar',
    JPY: 'Japanese yen',
    KWD: 'Kuwaiti Dinar',
    LSL: 'Lesotho loti',
    MAD: 'Moroccan dirham',
    MXN: 'Mexican peso',
    NOK: 'Norwegian krone',
    NPR: 'Nepalese rupee',
    NZD: 'New Zealand dollar',
    OMR: 'Rial Omani',
    PEN: 'Peruvian sol',
    PGK: 'Papua New Guinea Kina',
    PLN: 'Polish zloty',
    RON: 'Romanian leu',
    RUB: 'Russian ruble',
    SAR: 'Saudi riyal',
    SEK: 'Swedish krona',
    SGD: 'Singapore dollar',
    SZL: 'Swazi lilangeni',
    THB: 'Thai baht',
    TRY: 'Turkish lira',
    USD: 'US Dollar',
    UYU: 'Uruguayan peso',
    WST: 'Samoan Tala',
    ZAR: 'South African rand',
  },
  currenciesDopelniacz: {
    AED: 'UAE dirham',
    ARS: 'Argentine peso',
    AUD: 'Australian dollar',
    BDT: 'Bangladeshi taka',
    BGN: 'Bulgarian lev',
    BRL: 'Brazilian real',
    CAD: 'Canadian dollar',
    CHF: 'Swiss franc',
    CLP: 'Chilean peso',
    CNY: 'Chinese yuan',
    COP: 'Colombian peso',
    CZK: 'Czech koruna',
    DKK: 'Danish crown',
    EGP: 'Egyptian Pound',
    EUR: 'Euro',
    FJD: 'Fijian Dollar',
    GBP: 'British pound',
    GHS: 'Ghanaian Cedi',
    HKD: 'Hong Kong dollar',
    HRK: 'Croatian kuna',
    HUF: 'Hungarian forint',
    ILS: 'Israeli new shekel',
    INR: 'Indian Rupee',
    JOD: 'Jordanian dinar',
    JPY: 'Japanese yen',
    KWD: 'Kuwaiti Dinar',
    LSL: 'Lesotho loti',
    MAD: 'Moroccan dirham',
    MXN: 'Mexican peso',
    NOK: 'Norwegian krone',
    NPR: 'Nepalese rupee',
    NZD: 'New Zealand dollar',
    OMR: 'Rial Omani',
    PEN: 'Peruvian sol',
    PGK: 'Papua New Guinea Kina',
    PLN: 'Polish zloty',
    RON: 'Romanian leu',
    RUB: 'Russian ruble',
    SAR: 'Saudi riyal',
    SEK: 'Swedish krona',
    SGD: 'Singapore dollar',
    SZL: 'Swazi lilangeni',
    THB: 'Thai baht',
    TRY: 'Turkish lira',
    USD: 'US Dollar',
    UYU: 'Uruguayan peso',
    WST: 'Samoan Tala',
    ZAR: 'South African rand',
  },
  chart: {
    time: 'Time',
    rate: 'Rate',
    opening: 'Opening',
    highest: 'Highest',
    lowest: 'Lowest',
    closing: 'Closing',
    monthNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
    dayNames: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
  },
  networkErrorStatus: {
    slow: 'Problem with downloading exchange rates. Slow internet connection',
    noNetwork: 'Problem with downloading exchange rates. Check if you have an internet connection',
    default: 'Technical problems have occurred. Contact customer service',
  },
  calculatorForegin: {
    exchangeRates: 'Exchange rate',
    details: 'Details',
    amountYouSend: 'The amount you will send',
    countryYouSendMoney: 'The country you will send money to',
    foreignTransferFee: 'Foreign transfer fee',
    foreignTransferType: 'Foreign transfer type',
    exchangeAmount: 'Exchange amount',
    amountRecipientReceive: 'The amount the recipient will receive',
    minAmount: 'The exchange amount is too low.',
    notSellCurrency: 'Currency sales are unavailable.',
  },
  countries: {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AN: 'Netherlands Antilles',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'American Samoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Åland Islands',
    AZ: 'Azerbaijan',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei Darussalam',
    BO: 'Bolivia',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos Islands',
    CD: 'Democratic Republic of the Congo',
    CF: 'Central African Republic',
    CG: 'Congo',
    CH: 'Switzerland',
    CI: 'Ivory Coast',
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Columbia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CW: 'Curacao',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic ',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    EH: 'Western Sahara',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands',
    FM: 'Micronesia',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'Great Britain',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey Island',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'South Georgia and the South Sandwich Islands',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Heard Island and McDonald Islands',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Island of Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoro Islands',
    KN: 'Saint Kitts and Nevis',
    KP: 'North Korea',
    KR: 'South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MF: 'Sint Maarten',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Burma',
    MN: 'Mongolia',
    MO: "Macao Special Administrative Region of the People's Republic of China",
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint-Pierre and Miquelon',
    PN: 'Pitcairn',
    PR: 'Puerto Rico',
    PS: 'Palestine',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena',
    SI: 'Slovenia',
    SJ: 'Svalbard and Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Surinam',
    ST: 'São Tomé and Príncipe',
    SV: 'Salvador',
    SY: 'Syria',
    SZ: 'Suazi',
    TC: 'Turks and Caicos',
    TD: 'Chad',
    TF: 'French Southern and Antarctic Lands',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'East Timor',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'United States Minor Outlying Islands',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Vatican',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela',
    VG: 'British Virgin Islands',
    VI: 'United States Virgin Islands',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'Republic of South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
}
