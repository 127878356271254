import i18next from 'i18next'
import en from './languages/en'
import pl from './languages/pl'
import { getCurrentLanguage, SupportedLanguages } from 'utils/current-language'

const initI18n = () => {
  const addResourceBundle = (language: string, translations) => {
    i18next.addResourceBundle(language, 'translation', translations)
  }

  return i18next
    .init({
      lng: getCurrentLanguage(),
      fallbackLng: SupportedLanguages.pl,
      debug: false,
      returnObjects: false,
      interpolation: {
        escapeValue: true,
      },
      resources: {
        en: {
          translation: en,
        },
        pl: {
          translation: pl,
        },
      },
      nsSeparator: '::',
    })
    .then(() => {
      switch (getCurrentLanguage()) {
        case 'en':
          return import('./languages/en').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        default:
          return import('./languages/pl').then((translations) => {
            addResourceBundle('pl', translations.default)
          })
      }
    })
}

const i18n = (key, props?): string => i18next.t(key, { ...props, returnDetails: false }) as any

const getTranslationsMap = (): any => i18next.getResourceBundle(getCurrentLanguage(), '')

export { initI18n, i18n, getTranslationsMap }
