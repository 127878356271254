import { CalculatorInputName } from 'models/calculator'
import { SelectOption } from 'components/select-preact/select-preact'
import { useCallback } from 'preact/compat'

export interface CalculatorInputProps {
  className?: string
  label: string
  amount: string
  currency: string
  currencies: string[]
  name: CalculatorInputName
  handleCurrencyChange: (prop: string, value: string) => void
  handleAmountChange: (prop: string, value: string) => void
}

const useCalculatorInput = (props: CalculatorInputProps) => {
  const handleAmount = useCallback(
    (value: string): void => {
      props.handleAmountChange(props.name, value)
    },
    [props.name],
  )

  const handleCurrency = useCallback(
    (option: SelectOption): void => {
      props.handleCurrencyChange(props.name, option.value)
    },
    [props.name],
  )

  return { handleAmount, handleCurrency }
}

export default useCalculatorInput
