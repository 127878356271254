import NumberInput from 'components/number-input'
import { h } from 'preact'
import InputFieldSpinner from 'components/input-field-spinner'

export interface AmountInputFieldsetProps {
  amount: string
  handleAmount: (value: string) => void
  isSpinner: boolean
  classNames?: string
  id?: string
  wrapperClassNames?: string
  error?: string
  maxLength?: number
  label?: string
}

const AmountInputFieldset = (props: AmountInputFieldsetProps) => (
  <div className={`${props.wrapperClassNames || ''}`}>
    <NumberInput
      id={props.id}
      className={props.classNames}
      maxLength={props.maxLength || 7}
      value={props.amount}
      onChange={props.handleAmount}
      error={props.error}
      label={props.label}
    />
    {props.isSpinner && <InputFieldSpinner />}
  </div>
)

export default AmountInputFieldset
