import CalculatorSelect from 'common-modules/calculator/calculator-select'
import SelectPreact from 'components/select-preact/select-preact'
import { h } from 'preact'
import selectHelper from 'services/select-helper'

export default class MainCalculatorSelect extends CalculatorSelect {
  public render() {
    return (
      <div className="hero-form__dropdown-holder clearfix">
        <SelectPreact
          options={this.state.options}
          chosenItem={selectHelper.findByValue(this.props.value, this.createOptions())}
          onChange={this.onChange}
          name={''}
          id={''}
          isSearch={true}
          buttonClasses={'is-big-and-puffy in-hero-form-with-currency'}
          listClasses={'for-currency-big-dropdown is-near-right-edge'}
        />
      </div>
    )
  }
}
