export const currenciesMap = {
  AED: 'Dirham ZEA',
  ARS: 'Peso argentyńskie',
  AUD: 'Dolar australijski',
  BDT: 'Taka bengalska',
  BGN: 'Lew bułgarski',
  BRL: 'Real brazylijski',
  CAD: 'Dolar kanadyjski',
  CHF: 'Frank szwajcarski',
  CLP: 'Peso chilijskie',
  CNY: 'Juan chiński',
  COP: 'Peso kolumbijskie',
  CZK: 'Korona czeska',
  DKK: 'Korona duńska',
  EGP: 'Funt egipski',
  EUR: 'Euro',
  FJD: 'Dolar fidżi',
  GBP: 'Funt brytyjski',
  GHS: 'Cedi ghańskie',
  HKD: 'Dolar hongkoński',
  HRK: 'Kuna chorwacka',
  HUF: 'Forint węgierski',
  ILS: 'Nowy szekel izraelski',
  INR: 'Rupia indyjska',
  JOD: 'Dinar jordański',
  JPY: 'Jen japoński',
  KWD: 'Dinar kuwejcki',
  LSL: 'Loti lesotyjskie',
  MAD: 'Dirham marokański',
  MXN: 'Peso meksykańskie',
  NOK: 'Korona norweska',
  NPR: 'Rupia nepalska',
  NZD: 'Dolar nowozelandzki',
  OMR: 'Rial omański',
  PEN: 'Sol peruwiański',
  PGK: 'Kina papuańska',
  PLN: 'Polski złoty',
  RON: 'Leja rumuńska',
  RUB: 'Rubel rosyjski',
  SAR: 'Rial saudyjski',
  SEK: 'Korona szwedzka',
  SGD: 'Dolar singapurski',
  SZL: 'Lilangeni',
  THB: 'Bat tajlandzki',
  TRY: 'Lira turecka',
  USD: 'Dolar amerykański',
  UYU: 'Peso urugwajskie',
  WST: 'Tala samoańska',
  ZAR: 'Rand południowoafrykański',
}

export const currenciesCountriesMap = {
  PLN: 'Polska',
  EUR: 'Europa',
  USD: 'Stany Zjednoczone',
  CHF: 'Szwajcaria',
  ARS: 'Argentyna',
  GBP: 'Wielka Brytania',
  AUD: 'Australia',
  BGN: 'Bułgaria',
  BRL: 'Brazylia',
  CAD: 'Kanada',
  CZK: 'Czechy',
  DKK: 'Dania',
  HKD: 'Hongkong',
  HRK: 'Chorwacja',
  MXN: 'Meksyk',
  NOK: 'Norwegia',
  NZD: 'Nowa Zelandia',
  PEN: 'Peru',
  RON: 'Rumunia',
  RUB: 'Rosja',
  SEK: 'Szwecja',
  SGD: 'Singapur',
  TRY: 'Turcja',
  UYU: 'Urugwaj',
  ZAR: 'RPA',
  AED: 'Dirham ZEA',
  BDT: 'Taka bengalska',
  CNY: 'Juan chiński',
  EGP: 'Funt egipski',
  FJD: 'Dolar fidżi',
  GHS: 'Cedi ghańskie',
  INR: 'Rupia indyjska',
  JOD: 'Dinar jordański',
  KWD: 'Dinar kuwejcki',
  LSL: 'Loti lesotyjskie',
  MAD: 'Dirham marokański',
  NAD: 'Dolar namibijski',
  NPR: 'Rupia nepalska',
  OMR: 'Rial omański',
  PGK: 'Kina papuańska',
  SAR: 'Rial saudyjski',
  SZL: 'Lilangeni',
  THB: 'Bat tajlandzki',
  WST: 'Tala samoańska',
}

const currencyHelper = {
  getCurrencyName: (currency: string): string => currenciesMap[currency] || currency,
  getCurrencyCountry: (currency: string): string => currenciesCountriesMap[currency] || currency,
}

export default currencyHelper
