import { SelectOption } from 'components/select-preact/select-preact'
import { JSX } from 'preact'

const selectHelper = {
  getFilteredSelectOptions: (options: SelectOption[], value: string): SelectOption[] =>
    options.filter(
      (option) => option.searchValue && option.searchValue.toLowerCase().includes(value.toLowerCase().trim()),
    ),
  getEmptyOption: (): SelectOption => ({
    value: '',
    template: null,
  }),
  findByValue: (value: string, options: SelectOption[]): SelectOption =>
    options.find((option) => option.value === value) || selectHelper.getEmptyOption(),
  createOption: (value: string, template: JSX.Element | string, searchValue?: string): SelectOption => ({
    value,
    template,
    ...(searchValue && { searchValue }),
  }),
}

export default selectHelper
