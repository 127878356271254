import { h } from 'preact'
import AmountInputFieldset from 'components/amount-input'
import useCalculatorInput, { CalculatorInputProps } from 'common-modules/calculator/calculator-input'
import MainCalculatorSelect from './calculator-select'

const MainCalculatorInput = (props: CalculatorInputProps) => {
  const { handleAmount, handleCurrency } = useCalculatorInput(props)

  return (
    <div className={`hero-form__input-block ${props.className || ''}`}>
      <div className="hero-form__input-block-label">
        <div className="hero-form__input-block-label-text">{props.label}</div>
      </div>
      <div className="hero-form__combined-inputs">
        <AmountInputFieldset
          label={props.label}
          amount={props.amount}
          handleAmount={handleAmount}
          isSpinner={false}
          wrapperClassNames="hero-form__text-input-holder"
          classNames="bem-text-input in-hero-form-big-enter-amount w-input"
        />
        <MainCalculatorSelect onChange={handleCurrency} currencies={props.currencies} value={props.currency} />
      </div>
    </div>
  )
}

export default MainCalculatorInput
