import classNames from 'classnames'
import { Component, h } from 'preact'

export interface NumberInputProps {
  id: string
  value: string
  error?: string
  decimalPlaces?: number
  className?: string
  placeholder?: string
  onChange: (value: any) => void
  onFocus?: () => void
  disabled?: boolean
  maxLength?: number
  label?: string
  ariaLabel?: string
}
export default class NumberInput extends Component<NumberInputProps, {}> {
  public render() {
    return (
      <input
        className={classNames(this.props.className, {
          'has-error': this.hasError,
        })}
        type="tel"
        inputMode="decimal"
        value={this.currentValue}
        placeholder={this.props.placeholder}
        onInput={this.onChange}
        onFocus={this.onFocus}
        id={this.props.id}
        disabled={this.props.disabled}
        maxLength={this.props.maxLength}
        aria-label={this.props.label}
      />
    )
  }

  public onChange = (event): void => {
    const value = this.parseValue(event.target.value).replace(/,/g, '.')
    this.props.onChange(value)
  }

  public onFocus = (): void => this.props.onFocus && this.props.onFocus()

  public parseValue = (inputValue): string => this.transformInputValue(inputValue, this.props.decimalPlaces || 2)

  public transformInputValue = (inputValue: string, decimalPlaces: number): string => {
    if (!inputValue) {
      return ''
    }

    const decimal = decimalPlaces || 2
    const transformedInput: string[] = inputValue
      .toString()
      .replace(',', '.')
      .replace(/[^0-9.0-9{2}]+/g, '')
      .split('.')
      .slice(0, 2)

    if (transformedInput.length > 1 && !transformedInput[0]) {
      transformedInput[0] = '0'
    }

    if (transformedInput[1]) {
      transformedInput[1] = transformedInput[1].substr(0, decimal)
    }

    if (transformedInput[0].startsWith('0') && transformedInput[0].length > 1) {
      transformedInput[0] = transformedInput[0].slice(1)
    }

    return decimal > 0 ? transformedInput.join(',') : transformedInput[0]
  }

  get hasError(): boolean {
    return this.props.error ? this.props.error !== '' : false
  }

  get currentValue(): string {
    return this.parseValue(this.props.value)
  }
}
