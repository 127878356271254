import { h } from 'preact'
import CalculatorApp from 'common-modules/calculator'
import MainCalculatorFormView from './calculator-form'

export default class MainCalculatorApp extends CalculatorApp {
  public render() {
    return (
      <div id="preact_root">
        <div className="hero__form-holder">
          <div className="hero-form">
            <MainCalculatorFormView
              currencies={this.currencies}
              routeFusionCurrencies={this.state.settings.routeFusionCurrencies}
              crownAgentsCurrencies={this.state.settings.crownAgentsCurrencies}
            />
          </div>
        </div>
      </div>
    )
  }
}
