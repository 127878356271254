import { getCurrentLanguage } from 'utils/current-language'
import en from './languages/en'
import pl from './languages/pl'

export const t9n = (key: string, lang: string = getCurrentLanguage()): string => {
  const isNested = key.includes('.')
  const arr = isNested ? key.split('.') : [key]
  switch (lang) {
    case 'en':
      return isNested ? en[arr[0]][arr[1]] : en[key]
    default:
      return isNested ? pl[arr[0]][arr[1]] : pl[key]
  }
}
