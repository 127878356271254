import cookieService from './cookies'

const storageService = {
  hasLocalStorage(): boolean {
    let isLocalStorage = false
    try {
      const x = 'storageTest'
      localStorage.setItem(x, x)
      localStorage.removeItem(x)
      isLocalStorage = true
    } catch (e) {
      isLocalStorage = false
    }
    return isLocalStorage
  },
  setItem(key: string, value: string): void {
    if (this.hasLocalStorage()) {
      localStorage.setItem(key, value)
    } else {
      cookieService.setItem(key, value)
    }
  },
  getItem(key: string): string | null {
    return this.hasLocalStorage() ? localStorage.getItem(key) : cookieService.getItem(key)
  },
  removeItem(key: string): void {
    if (this.hasLocalStorage()) {
      localStorage.removeItem(key)
    } else {
      cookieService.removeItem(key)
    }
  },
}

export default storageService
