import { Component, h } from 'preact'
import { SelectOption } from 'components/select-preact/select-preact'

export interface SelectElementProps {
  option: SelectOption
  onClick: (option: SelectOption) => void
  isOptionClickBlocked?: (option: string) => boolean
}

export default class SelectElement extends Component<SelectElementProps, {}> {
  public render() {
    return <div onClick={this.selectElementIfNotBlocked}>{this.props.option.template}</div>
  }

  public selectElement = (): void => {
    this.props.onClick(this.props.option)
  }

  public selectElementIfNotBlocked = (): void => {
    this.props.isOptionClickBlocked && this.props.isOptionClickBlocked(this.props.option.value)
      ? undefined
      : this.selectElement()
  }
}
