import { Component } from 'preact'
import { CalculatorDirection, ExchangeDirection } from 'models/calculator'
import { MarketBrief } from 'models/market'
import marketService from 'services/market'
import {
  changeFormValue,
  createFormField,
  FormField,
  formFieldsToValues,
  formHasError,
  GenericTypeOfFormField,
  isFormFieldUpdated,
  validateForm,
} from '@currency-one/form-utils'
import { notSmallerorEqualThanValidator } from 'services/validators-functions'
import directExchangeService, { DirectExchangeCompareResult } from 'services/direct-exchange'
import { debounce } from 'utils/debounce'
import storageService from 'services/storage'

export interface CalculatorForm {
  exchangeDirection: FormField<ExchangeDirection>
  baseAmount: FormField<string>
  counterAmount: FormField<string>
  baseCurrency: FormField<string>
  counterCurrency: FormField<string>
}

export interface CalculatorFormStorage {
  exchangeDirection: ExchangeDirection
  baseAmount: string
  counterAmount: string
  baseCurrency: string
  counterCurrency: string
}

export interface CalculatorFormViewState {
  isError: boolean
  isSwapped: boolean
  form: CalculatorForm
  marketBrief: MarketBrief[]
  compareResult: DirectExchangeCompareResult | undefined
  prevForm: CalculatorForm
}

export interface CalculatorFormViewProps {
  currencies: string[]
  routeFusionCurrencies?: string[]
  crownAgentsCurrencies?: string[]
}

export default class CalculatorFormView extends Component<CalculatorFormViewProps, CalculatorFormViewState> {
  constructor(props) {
    super(props)

    this.state = {
      isError: false,
      isSwapped: false,
      form: this.initForm,
      marketBrief: [],
      compareResult: undefined,
      prevForm: this.initForm,
    }
  }

  public async componentDidUpdate(_, prevState) {
    if (this.isFormValid && this.shouldUpdateEstimateAmount(prevState)) {
      storageService.hasLocalStorage() ? this.saveFormToLS() : this.setPrevForm()
      debounce(this.setCompareResult, 200)
    } else if (!this.isFormWithPLN) {
      this.fixNoPLN()
    } else if (!this.isFormWithDiffCurrencies) {
      this.fixSameCurrencies()
    }
  }

  public render() {
    return null
  }

  public onMarketBriefFetch = () => {
    this.setMarketBrief()
    if (this.isFormValid) {
      this.setCompareResult()
    }
  }

  public shouldUpdateEstimateAmount = (prevState: CalculatorFormViewState): boolean => {
    const { baseAmount, baseCurrency, counterAmount, counterCurrency, exchangeDirection } = this.state.form
    return (
      (isFormFieldUpdated(prevState.form.baseAmount, baseAmount) && exchangeDirection.val === 'forward') ||
      (isFormFieldUpdated(prevState.form.counterAmount, counterAmount) && exchangeDirection.val === 'back') ||
      isFormFieldUpdated(prevState.form.baseCurrency, baseCurrency) ||
      isFormFieldUpdated(prevState.form.counterCurrency, counterCurrency)
    )
  }

  public changeFormValue = <T extends keyof CalculatorForm, U extends CalculatorForm[T]>(
    fieldName: T,
    value: GenericTypeOfFormField<U>,
    callback?: () => any,
  ) => {
    this.setState(
      {
        form: changeFormValue(this.state.form, fieldName, value),
      },
      () => callback && callback(),
    )
  }

  public handleAmountChange = (propName: string, value: string): void => {
    this.setState({
      form: {
        ...this.createForm(formFieldsToValues(this.state.form)),
        exchangeDirection: createFormField(propName === 'baseAmount' ? 'forward' : 'back'),
        [propName]: createFormField(value, [{ validator: notSmallerorEqualThanValidator, params: () => '0' }]),
      },
    })
  }

  public handleCurrencyChange = (propName: string, value: string): void => {
    this.changeFormValue(propName === 'baseAmount' ? 'baseCurrency' : 'counterCurrency', value)
  }

  public setCompareResult = async (): Promise<void> => {
    this.setState({ isError: false })

    const { exchangeDirection, baseAmount, baseCurrency, counterAmount, counterCurrency } = this.state.form
    const amount = exchangeDirection.val === 'forward' ? baseAmount.val : counterAmount.val
    const baseCurrencyVal = exchangeDirection.val === 'forward' ? baseCurrency.val : counterCurrency.val
    const counterCurrencyVal = exchangeDirection.val === 'forward' ? counterCurrency.val : baseCurrency.val
    try {
      const { result } = await directExchangeService.getCompareResult(
        this.direction,
        amount,
        baseCurrencyVal,
        counterCurrencyVal,
      )
      this.changeFormValue(
        this.state.form.exchangeDirection.val === 'forward' ? 'counterAmount' : 'baseAmount',
        result.exchangeAmount,
      )
      this.setState({ compareResult: result })
    } catch (e) {
      this.setState({ isError: true })
    }
  }

  public setMarketBrief = (): void => {
    this.setState({ marketBrief: marketService.getMarketBrief() })
  }

  public swapInputs = (): void => {
    const newDirection = this.state.form.exchangeDirection.val === 'forward' ? 'back' : 'forward'
    this.setState({
      isSwapped: !this.state.isSwapped,
      form: {
        ...this.state.form,
        exchangeDirection: createFormField(newDirection),
        baseCurrency: this.state.form.counterCurrency,
        counterCurrency: this.state.form.baseCurrency,
        baseAmount: this.state.form.counterAmount,
        counterAmount: this.state.form.baseAmount,
      },
    })
  }

  public fixSameCurrencies = (): void => {
    const { counterCurrency, baseCurrency } = storageService.hasLocalStorage() ? this.formFromLS : this.state.prevForm
    this.setState({
      form: {
        ...this.state.form,
        baseCurrency: changeFormValue(this.state.form, 'baseCurrency', counterCurrency.val).baseCurrency,
        counterCurrency: changeFormValue(this.state.form, 'counterCurrency', baseCurrency.val).counterCurrency,
      },
    })
  }

  public fixNoPLN = (): void => {
    const isBaseCurrencyChanged = storageService.hasLocalStorage()
      ? isFormFieldUpdated(this.state.form.baseCurrency, this.formFromLS.baseCurrency)
      : isFormFieldUpdated(this.state.form.baseCurrency, this.state.prevForm.baseCurrency)
    this.changeFormValue(isBaseCurrencyChanged ? 'counterCurrency' : 'baseCurrency', 'PLN')
  }

  public saveFormToLS = (): void => {
    storageService.setItem('calculatorForm', JSON.stringify(formFieldsToValues(this.state.form)))
  }

  public setPrevForm = (): void => {
    this.setState({ prevForm: this.state.form })
  }

  get direction(): CalculatorDirection {
    return this.state.form.baseCurrency.val === 'PLN' ? 'BUY' : 'SELL'
  }

  get formFromLS(): CalculatorForm | null {
    const form = JSON.parse(storageService.getItem('calculatorForm')) as CalculatorFormStorage
    return form ? this.createForm(form) : null
  }

  get isFormWithPLN(): boolean {
    const { counterCurrency, baseCurrency } = this.state.form
    return [counterCurrency.val, baseCurrency.val].includes('PLN')
  }

  get isFormWithDiffCurrencies(): boolean {
    const { counterCurrency, baseCurrency } = this.state.form
    return counterCurrency.val !== baseCurrency.val
  }

  get isFormValid(): boolean {
    const validatedForm = validateForm(this.state.form)
    return this.isFormWithPLN && this.isFormWithDiffCurrencies && !formHasError(validatedForm)
  }

  get initForm(): CalculatorForm {
    return this.createForm({
      exchangeDirection: 'forward',
      baseAmount: '100',
      counterAmount: '422,59',
      baseCurrency: 'PLN',
      counterCurrency: 'EUR',
    })
  }

  get isSelectedCurrencyNotSupportedSales(): boolean {
    const { routeFusionCurrencies, crownAgentsCurrencies } = this.props
    const isNotEmpty = routeFusionCurrencies && crownAgentsCurrencies
    return isNotEmpty && [...routeFusionCurrencies, ...crownAgentsCurrencies].includes(this.state.form.baseCurrency.val)
  }

  public createForm = ({
    exchangeDirection,
    baseAmount,
    counterAmount,
    baseCurrency,
    counterCurrency,
  }: CalculatorFormStorage): CalculatorForm => ({
    exchangeDirection: createFormField(exchangeDirection),
    baseAmount: createFormField(baseAmount),
    counterAmount: createFormField(counterAmount),
    baseCurrency: createFormField(baseCurrency),
    counterCurrency: createFormField(counterCurrency),
  })
}
