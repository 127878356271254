export function formatNumber(num: number | string, fractionDigits: number): string {
  return parseFloat(num.toString()).toLocaleString('pl-PL', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}

export function formatNumberWithSep(num: number | string): string {
  return formatNumber(num, 2).replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')
}

export function localeStringToFloat(value: string): string {
  return value.trim().replace(/,/g, '.')
}
