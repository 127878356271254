import { FormFieldValidatorFn, FormFieldValidatorFnWithParams } from '@currency-one/form-utils'
import {
  isValueValidator,
  isEmailValidator,
  isPasswordValidator,
  isNotSmallerValidator,
  isNotHigherValidator,
  isNotSmallerOrEqualValidator,
} from '@currency-one/validators'
import { localeStringToFloat } from 'utils/format-number'
import { t9n } from 't9n/t9n'

export const checkedValidator: FormFieldValidatorFn<boolean> = (value: boolean): string =>
  isValueValidator(value) ? '' : t9n('emailValidator.requiredField')

export const emptyValidator: FormFieldValidatorFn<string> = (value: string): string =>
  isValueValidator(value) ? '' : t9n('emailValidator.notEmptyField')

export const minValueValidator: FormFieldValidatorFnWithParams<string> = (minValue: string) => (value: string) =>
  isNotSmallerValidator(localeStringToFloat(value), localeStringToFloat(minValue))
    ? ''
    : `${t9n('emailValidator.minValue')} ${minValue}`

export const notSmallerorEqualThanValidator: FormFieldValidatorFnWithParams<string> =
  (minValue: string) => (value: string) =>
    isNotSmallerOrEqualValidator(localeStringToFloat(value), localeStringToFloat(minValue))
      ? ''
      : `${t9n('emailValidator.minValue')} ${minValue}`

export const maxValueValidator: FormFieldValidatorFnWithParams<string> = (maxValue: string) => (value: string) =>
  isNotHigherValidator(localeStringToFloat(value), localeStringToFloat(maxValue))
    ? ''
    : `${t9n('emailValidator.maxValue')} ${maxValue}`

export const emailValidator: FormFieldValidatorFn<string> = (email: string): string =>
  isEmailValidator(email) ? '' : t9n('emailValidator.incorrectEmail')

export const passwordValidator: FormFieldValidatorFn<string> = (password: string): string =>
  isPasswordValidator(password) ? '' : t9n('emailValidator.toShortPassowrd')
