import { MarketBrief } from 'models/market'
import { useEffect, useState } from 'preact/hooks'
import { CalculatorDirection } from 'models/calculator'

export interface CalculatorRateViewProps {
  direction: CalculatorDirection
  marketBrief: MarketBrief[]
  baseCurrency: string
  counterCurrency: string
  shouldUpdate: boolean
}

const useCalculatorRateView = (props: CalculatorRateViewProps) => {
  const [rate, setRate] = useState(0)
  const [pair, setPair] = useState(getPair())

  useEffect(() => {
    if (props.shouldUpdate) {
      setPair(getPair)
    }
  }, [props.baseCurrency, props.counterCurrency, props.shouldUpdate])

  useEffect(() => {
    if (props.marketBrief.length) {
      assignChartRate()
    }
  }, [props.marketBrief, props.direction, pair])

  function getPair(): string {
    return props.baseCurrency === 'PLN'
      ? `${props.counterCurrency}_${props.baseCurrency}`
      : `${props.baseCurrency}_${props.counterCurrency}`
  }

  const assignChartRate = (): void => {
    const market = props.marketBrief.find((el) => el.pair === pair)
    if (market) {
      setRate(props.direction === 'BUY' ? market.directExchangeOffers.sellNow : market.directExchangeOffers.buyNow)
    }
  }

  return { rate, pair }
}

export default useCalculatorRateView
